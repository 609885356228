<template>
  <div id="common-cart" :class="[{ [$style.is_show]: cartState.isShowCart }, $style.side_cart]">
    <AtomsConsumerButton :class="$style.close" @click="closeCart" />
    <div v-if="isLoaded && cartState" :class="$style.container">
      <MoleculesConsumerHeading title="Cart" sub-title="カート" align="left" variant="sub" />
      <template v-if="cartLine.length >= 1">
        <div :class="$style.body_contents">
          <p v-if="isEGift" :class="$style.info_message">eギフト受け取りURLは購入完了ページで発行されます</p>
          <p v-if="isEmbroidery" :class="$style.info_message">
            刺繍内容やラッピング設定を変更する場合、カートから商品を一度削除してください
          </p>
          <p v-if="isEmbroidery" :class="$style.info_message">
            【刺繍サービスについて】<br />
            {{
              // TODO: 父の日キャンペーン時は修正する
              embroideryExclusive
                ? "2024年6月12日に発送し、6月13日～16日頃お届け予定です。"
                : "・刺繍加工のため、お届けまでに14日程度お時間をいただきます。"
            }}<br />
            ・代引き引換での購入はできません。<br />
            ・加工済み商品のキャンセル・返品・交換は原則不可となります。<br />
            ご了承の上、ご注文ください。
          </p>
          <OrderSideCartList
            :cart-list="cartListWithGift"
            @open-select-gift="openSelectGift"
            @init-select-gift="initSelectGift"
          />
        </div>
        <div :class="$style.footer_contents">
          <div :class="$style.order_price_container">
            <OrderPrice
              :total="cartPriceState.total"
              :sub-total="cartPriceState.subTotal"
              :postage-price="cartPriceState.postagePrice"
              :discount-price="cartPriceState.discountPrice"
              :commission-price="cartPriceState.commissionTotal"
              :gift-price="cartPriceState.giftPrice"
              :group-wrapping-price="cartPriceState.groupWrappingPrice"
              :embroidery-price="cartPriceState.embroideryPrice"
              :temporary-mile="cartPriceState.temporaryMile"
              :remain-amount="cartPriceState.remainAmountItem.remainAmount"
              :recommend-discount-method="cartPriceState.remainAmountItem.recommendDiscountMethod"
              :recommend-discount-fixed-amount="cartPriceState.remainAmountItem.recommendDiscountFixedAmount"
              :recommend-discount-rate="cartPriceState.remainAmountItem.recommendDiscountRate"
              :is-coupon-applied="cartPriceState.usedCoupon.length > 0"
            />
          </div>
          <div :class="$style.body_contents">
            <p :class="$style.danger_message"> ※サイズ・ラッピング設定等のお間違えがないか、今一度お確かめください </p>
            <p :class="$style.danger_message">
              ※ギフトラッピングの設定漏れが大変多くなっております。今一度設定をお確かめください
            </p>
          </div>
          <div :class="$style.buttons">
            <AtomsConsumerButton variant="primary" block :disabled="!calculateLoaded" @click="purchase()">
              ご購入手続きへ
            </AtomsConsumerButton>
            <AtomsConsumerButton variant="secondary" block :disabled="!calculateLoaded" @click="closeCart">
              カートを閉じる
            </AtomsConsumerButton>
          </div>
        </div>
        <hr v-if="recommendProducts.length" />
        <CartRecommendation :recommend-products="recommendProducts" />
      </template>
      <div v-else :class="$style.order_cart_not_box">
        <p>現在、カートに商品はありません</p>
        <AtomsConsumerButton variant="secondary" block @click="closeCart">カートを閉じる</AtomsConsumerButton>
        <h3>Categories</h3>
        <div :class="$style.buttons">
          <AtomsConsumerLink url="/night-conditioning">
            <AtomsConsumerButton variant="primary" block>Night Conditioning</AtomsConsumerButton>
          </AtomsConsumerLink>
          <AtomsConsumerLink url="/day-conditioning">
            <AtomsConsumerButton variant="primary" block>Day Conditioning</AtomsConsumerButton>
          </AtomsConsumerLink>
        </div>
      </div>
    </div>
    <div v-if="cartState && cartState.isClass" id="side-cart-overlay" :class="$style.overlay_area" @click="closeCart" />
  </div>
  <MoleculesConsumerModal v-model="visibleSelectGift" :class="$style.gift_modal">
    <PagePartsCartPartsSettingGift
      :gift-wrappings="selectGiftCart?.wrapping ?? []"
      :gift-messages="selectGiftCart?.message ?? []"
      :gift-cards="selectGiftCart?.card ?? []"
      :cart-detail="selectGiftCart?.cartDetail"
      :is-card-only="selectGiftCart?.cartDetail.product.is_wrapping"
      @confirm="wrappingConfirm"
      @cancel="cancelGiftModal"
    />
  </MoleculesConsumerModal>
</template>

<script lang="ts" setup>
import { CartGift, CartItem } from "@tential/ec-gql-schema/models/order"
import { isEmbroideryProductsInCart, embroideryExclusiveProductsInCart } from "@tential/ec-gql-schema/utils/embroidery"
import { ProductAndSku } from "@tential/ec-gql-schema/types/cart"
import dayjs from "dayjs"
import { cloneDeep } from "~/utils/functions/common"
import { SkuFragmentFragment } from "~/types/type.generated"
import { useStore } from "~/stores"
import { useCart } from "~/composables/useCart"
import { useToast } from "~/state"
import { CartListWithGift } from "~/types/cart"
import type { ApiRecommendSideCartProductsResponse, RecommendProduct } from "~/types/server/api/recommend"

// ATTENTIAL: 送料固定
const store = useStore()
const route = useRoute()
const { cartState, closeCart, cartLine, cartPriceState, calculateLoaded, removeCartItem, addCartItem } = useCart()
const { proceed } = useConfirmDialog()
const { filterIndividualGiftWrappings, filterIndividualMessages, filterIndividualCards, fetchGiftPattern } = useGift()

const isLoaded = ref<boolean>(false)
const isEGift = computed(() => store.eGift.is_select)
const visibleSelectGift = ref<boolean>(false)
/** ギフト編集中のカートのsku_id */
const selectGiftSkuId = ref<string>("")
/** ギフト編集中のカートがラッピング未設定か */
const isNotWrappingSelectGift = ref<boolean>(false)

const isSendRecommendViewForGA = ref<boolean>(false)

const isEmbroidery = computed(() => isEmbroideryProductsInCart(store.cartList))

const embroideryExclusive = computed(() => embroideryExclusiveProductsInCart(store.cartList))
// おすすめ商品
const recommendProducts = ref<RecommendProduct[]>([])
// カート内の商品が変更されたかをチェックするためのカート情報
const latestCartList = ref<CartItem[]>([])

const updateRecommendProducts = () => {
  latestCartList.value = cloneDeep(store.cartList)

  // カートに商品が入っていない場合は何もしない
  if (store.cartList.length === 0) {
    return
  }

  // 商品閲覧履歴が無い場合は何もしない(商品閲覧履歴はレコメンドAPIに必須なため)
  // 現状商品閲覧履歴が無いのにカートに商品が入ることは無いはずだが、今後もし一覧画面から直接カートに入れるとかできるようになったら考慮が必要かも
  if (store.productBrowsingHistory.length === 0) {
    return
  }

  const localUserId = localStorage.getItem("localUserId") ? (localStorage.getItem("localUserId") as string) : ""
  $fetch<ApiRecommendSideCartProductsResponse>("/api/recommend/side_cart", {
    method: "POST",
    body: {
      local_user_id: localUserId,
      pv_products: store.productBrowsingHistory
        .filter((history) => history.pv_timestamp)
        .map((history) => ({
          product_id: history.document_id,
          pv_timestamp: history.pv_timestamp,
        }))
        .slice(0, 100),
      cart_products: store.cartList
        .filter((cart) => cart.add_to_cart_timestamp)
        .map((cart) => ({
          product_id: cart.product_id,
          add_to_cart_timestamp: cart.add_to_cart_timestamp,
        })),
      local_timestamp: dayjs().unix(), // 秒（ミリ秒ではないことに注意）
    },
  }).then((results) => {
    const products = results?.products ?? []
    recommendProducts.value = products
    // GAの送信(おすすめ商品に変更が無くてもレコメンドAPIをリクエストした場合は送信する)
    if (cartState.isShowCart) {
      isSendRecommendViewForGA.value = false
      gaPushForView()
    } else {
      // サイドカートが閉じている場合は次回表示時に送信する
      isSendRecommendViewForGA.value = true
    }
  })
}

const toast = useToast()

const purchase = () => {
  // ギフト商品＆ラッピング済み商品を除いた商品の数量
  const notGiftSkuQuantity = cartLine.value.reduce((total, cartItem) => {
    if (!cartItem.product.is_gift && !cartItem.product.is_wrapping) return total + cartItem.count
    return total
  }, 0)
  for (const item of cartLine.value) {
    if (item.product.order_quantity_limit && item.product.order_quantity_limit > 0) {
      if (item.product.order_quantity_limit_type === "order") {
        // 数量が購入点数制限以上の場合
        if (item.quantity > item.product.order_quantity_limit) {
          toast?.showErrorToasted(
            `${item.product.name}は、1回のご注文ごとに${item.product.order_quantity_limit}個までご購入可能です`,
          )
          return
        }
      } else if (item.product.order_quantity_limit_type === "not_gift_sku") {
        // 数量がカート個数*購入点数制限以上の場合
        if (item.quantity > item.product.order_quantity_limit * notGiftSkuQuantity) {
          toast?.showErrorToasted(
            `${item.product.name}は、商品1点（ラッピング袋・カードを除く）につき${item.product.order_quantity_limit}個までご購入可能です`,
          )
          return
        }
      }
    }
  }
  const cartListLength = store.cartList.length
  if (!cartListLength) {
    toast?.showErrorToasted("カートに商品が入っていません")
    return
  }
  return navigateTo("/order/new")
}

const cartListWithGift = computed(() => {
  const giftPatterns = cartLine.value.map((item: ProductAndSku): CartListWithGift => {
    // ラッピング済み商品・ギフト商品・ラッピング不可商品以外はギフト設定可能とする
    if (item.product.is_gift || item.product.is_wrapping) {
      return {
        wrapping: [],
        message: [],
        card: [],
        cartDetail: item,
      }
    }
    return {
      wrapping: filterIndividualGiftWrappings(item.product.document_id, null, item.sku as SkuFragmentFragment),
      message: filterIndividualMessages(item.product.document_id, item.sku.document_id),
      card: filterIndividualCards(item.product.document_id, item.sku.document_id),
      cartDetail: item,
    }
  })
  return giftPatterns
})

const openSelectGift = async (skuId: string, isNotGift: boolean = false): Promise<void> => {
  selectGiftSkuId.value = skuId
  isNotWrappingSelectGift.value = isNotGift
  await fetchGiftPattern() // 取得回数が多くなる場合は都度取得しないようにする
  visibleSelectGift.value = true
}

const selectGiftCart = computed(() =>
  cartListWithGift.value.find((item) => item.cartDetail.sku.document_id === selectGiftSkuId.value),
)

const wrappingConfirm = async (tempSelectionGift: CartGift, isCard: boolean): Promise<void> => {
  if (!isCard && !tempSelectionGift.sku_id) {
    toast?.showErrorToasted("ラッピングを選択して下さい")
    return
  } else if (isCard && !tempSelectionGift.card_sku_id) {
    toast?.showErrorToasted("カードを選択して下さい")
    return
  }
  const cartItem = cloneDeep(selectGiftCart.value?.cartDetail)
  if (!cartItem) return
  isLoaded.value = false
  // ラッピング設定付きのカートを追加し、既存のカートを削除
  await addCartItem({
    product_id: cartItem.sku.product_id,
    sku_id: cartItem.sku.document_id,
    site_id: cartItem.sku.site_id,
    is_reservation: cartItem.sku.is_reservation,
    net_stock_quantity: cartItem.sku.net_stock_quantity,
    net_stock_quantity_limit: cartItem.sku.net_stock_quantity_limit,
    gift: {
      sku_id: tempSelectionGift.sku_id,
      message: tempSelectionGift.message,
      product_name: tempSelectionGift.product_name,
      sku_name: tempSelectionGift.sku_name,
      sku_code: tempSelectionGift.sku_code,
      size: tempSelectionGift.size,
      price: tempSelectionGift.price,
      img: tempSelectionGift.img,
      message_img: tempSelectionGift.message_img,
      message_price: tempSelectionGift.message_price,
      message_sku_code: tempSelectionGift.message_sku_code,
      message_sku_id: tempSelectionGift.message_sku_id,
      card: tempSelectionGift.card,
      card_img: tempSelectionGift.card_img,
      card_price: tempSelectionGift.card_price,
      card_sku_code: tempSelectionGift.card_sku_code,
      card_sku_id: tempSelectionGift.card_sku_id,
      product_id: tempSelectionGift.product_id,
    },
  })
  await removeCartItem({
    skuId: cartItem.sku.document_id,
    gift: isNotWrappingSelectGift.value ? undefined : cartItem.gifts?.[0],
  })
  visibleSelectGift.value = false
  selectGiftSkuId.value = ""
  isLoaded.value = true
  toast?.showSuccessToasted(`${isCard ? "メッセージカード" : "ギフトラッピング"}の設定を完了しました`)
}

const initSelectGift = async (skuId: string): Promise<void> => {
  if (!(await proceed("ラッピング設定を解除します。よろしいですか？"))) return
  isLoaded.value = false
  selectGiftSkuId.value = skuId
  const cartItem = cloneDeep(selectGiftCart.value?.cartDetail)
  if (cartItem) {
    // ラッピング設定付きのカートを追加し、既存のカートを削除
    await addCartItem({
      product_id: cartItem.product.document_id,
      sku_id: cartItem.sku.document_id,
      site_id: cartItem.sku.site_id,
      is_reservation: cartItem.sku.is_reservation,
      net_stock_quantity: cartItem.sku.net_stock_quantity,
      net_stock_quantity_limit: cartItem.sku.net_stock_quantity_limit,
    })
    await removeCartItem({
      skuId: cartItem.sku.document_id,
      gift: cartItem.gifts?.[0],
    })
  }
  isLoaded.value = true
  toast?.showSuccessToasted("ギフトラッピングの設定を解除しました")
}

const cancelGiftModal = (): void => {
  // モーダルを閉じる
  visibleSelectGift.value = false
  selectGiftSkuId.value = ""
}

const { trackRecommendProductView } = useGtagEvent()

const gaPushForView = () => {
  recommendProducts.value.forEach((product) => {
    trackRecommendProductView(product)
  })
}

watch(
  () => cartState.isShowCart,
  (value) => {
    if (value) {
      window.history.pushState(null, "", null)
      window.addEventListener("popstate", closeCart)

      // カート情報に変更がある場合はおすすめ商品を取得
      if (JSON.stringify(store.cartList) !== JSON.stringify(latestCartList.value)) {
        updateRecommendProducts()
      } else if (isSendRecommendViewForGA.value) {
        // GAの送信がまだの場合は送信する
        isSendRecommendViewForGA.value = false
        gaPushForView()
      }
    } else {
      window.removeEventListener("popstate", closeCart)
    }
  },
)

watch(
  () => store.cartList.length,
  (value: number) => {
    if (value === 0 && store.eGift.is_select) store.resetEGift()
  },
)

watch(
  () => route.fullPath,
  () => {
    closeCart()
  },
)

watch(
  () => store.cartList,
  () => {
    // カートから商品が削除された場合はおすすめ商品を再取得
    // ただしsku違いの同じ商品がカートに入っていた場合に片方のskuだけ削除した場合は再取得しない
    if (
      cartState.isShowCart &&
      Array.from(new Set(store.cartList.map((cart) => cart.product_id))).length <
        Array.from(new Set(latestCartList.value.map((cart) => cart.product_id))).length
    ) {
      updateRecommendProducts()
    }
  },
  {
    deep: true,
  },
)

onMounted(() => {
  isLoaded.value = true
})
</script>

<style scoped module lang="scss">
.side_cart {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 120;
  width: 100%;
  max-width: 450px;
  height: 100vh;
  height: 100dvh;
  background: $primary-10;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  &.is_show {
    transform: translateX(0);
  }
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 15px;
    cursor: pointer;
    z-index: 121;
    &::before,
    &::after {
      position: absolute;
      top: 14px;
      left: 0px;
      width: 32px;
      height: 1px;
      content: "";
      background: $primary;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  .container {
    height: 100vh;
    height: 100dvh;
    padding: 2rem 1rem 2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .body_contents {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .danger_message {
        font-size: $font-size-14;
        color: $danger;
      }
      .info_message {
        font-size: $font-size-14;
        color: $primary;
      }
    }
    .order_cart_not_box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      p {
        font-size: $font-size-16;
        color: $primary;
      }
      h3 {
        font-size: $font-size-16;
        color: $primary;
      }
      .buttons {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        a {
          width: 100%;
        }
      }
    }
    .footer_contents {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .order_price_container {
        padding: 1rem;
      }
      .buttons {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
}
.gift_modal {
  z-index: 130 !important;
}
</style>
