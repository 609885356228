<template>
  <div id="common-cart" :class="{ '_open-cart': cartState.isShowCart, 'side-cart': true }">
    <div class="container cart-container _tential">
      <div class="_cart-area">
        <div v-if="isLoaded" class="_cart-contents">
          <div class="logo page-parts-sp">
            <button class="_close" @click="closeCart" />
          </div>
          <div v-if="cartState && cartState.isShowCart" class="_header-contents _tential">
            <div class="_layer">
              <div class="_title flex flex-x-center">
                <h4>{{ getOverseasTxtByLang(language, "orderDetails") }}</h4>
              </div>
              <div class="_back-button" @click="closeCart"
                ><span>{{ getOverseasTxtByLang(language, "close") }}</span></div
              >
            </div>
          </div>
          <template v-if="cartLine.length >= 1">
            <div class="_body-contents _tential">
              <OverseasSideCartList :cart-list="cartLine" @open-select-gift="openSelectGift" />
            </div>
            <div class="_footer-contents _tential">
              <div class="_order-cart-box">
                <div class="_order-price-container">
                  <OverseasPrice
                    :total="cartPriceState.total"
                    :sub-total="cartPriceState.subTotal"
                    :postage-price="cartPriceState.postagePrice"
                    :discount-price="cartPriceState.discountPrice"
                    :commission-price="cartPriceState.commissionTotal"
                    :gift-price="cartPriceState.giftPrice"
                    :embroidery-price="cartPriceState.embroideryPrice"
                    :remain-amount="cartPriceState.remainAmountItem.remainAmount"
                    :recommend-discount-method="cartPriceState.remainAmountItem.recommendDiscountMethod"
                    :recommend-discount-fixed-amount="cartPriceState.remainAmountItem.recommendDiscountFixedAmount"
                    :recommend-discount-rate="cartPriceState.remainAmountItem.recommendDiscountRate"
                    :is-coupon-applied="cartPriceState.usedCoupon.length > 0"
                  />
                </div>
                <div class="_order-cart-button">
                  <button
                    :disabled="!calculateLoaded"
                    class="cart-btn purchase gtm_purchase_procedure_btn tential-black"
                    @click="purchase()"
                  >
                    {{ getOverseasTxtByLang(language, "proceedToCheckout") }}
                  </button>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="_order-cart-not-box">
            <p>{{ getOverseasTxtByLang(language, "cartIsEmpty") }}</p>
          </div>
        </div>
      </div>
      <div v-if="cartState && cartState.isClass" id="side-cart-overlay" class="_overlay-area" @click="closeCart"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "~/stores"
import { useCart } from "~/composables/useCart"
import { useToast } from "~/state"
import { getLang, getOverseasTxtByLang } from "~/utils/functions/overseas"

// ATTENTIAL: 送料固定
const store = useStore()
const route = useRoute()
const { cartState, closeCart, cartLine, cartPriceState, calculateLoaded } = useCart()

const isLoaded = ref<boolean>(false)
const language = navigator.language

const visibleSelectGift = ref<boolean>(false)
/** ギフト編集中のカートindex */
const selectGiftIndex = ref<number>(0)
/** ギフト編集中のカートがラッピング未設定か */
const isNotWrappingSelectGift = ref<boolean>(false)

const toast = useToast()

const purchase = async () => {
  // ギフト商品＆ラッピング済み商品を除いた商品の数量
  const notGiftSkuQuantity = cartLine.value.reduce((total, cartItem) => {
    if (!cartItem.product.is_gift && !cartItem.product.is_wrapping) return total + cartItem.count
    return total
  }, 0)
  for (const item of cartLine.value) {
    if (item.product.order_quantity_limit && item.product.order_quantity_limit > 0) {
      if (item.product.order_quantity_limit_type === "order") {
        // 数量が購入点数制限以上の場合
        if (item.quantity > item.product.order_quantity_limit) {
          toast?.showErrorToasted(
            `${item.product.name}は、1回のご注文ごとに${item.product.order_quantity_limit}個までご購入可能です`,
          )
          return
        }
      } else if (item.product.order_quantity_limit_type === "not_gift_sku") {
        // 数量がカート個数*購入点数制限以上の場合
        if (item.quantity > item.product.order_quantity_limit * notGiftSkuQuantity) {
          toast?.showErrorToasted(
            `${item.product.name}は、商品1点（ラッピング袋・カードを除く）につき${item.product.order_quantity_limit}個までご購入可能です`,
          )
          return
        }
      }
    }
  }
  const cartListLength = store.cartList.length
  if (!cartListLength) {
    toast?.showErrorToasted("no items in cart")
    return
  }
  const redirect_url = await $fetch("/api/benly_checkout", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: {
      cartList: store.cartList,
      total: cartPriceState.total,
      discountPrice: cartPriceState.discountPrice,
      subTotal: cartPriceState.subTotal,
      lang: getLang(language),
    },
  })
  if (typeof redirect_url === "string") {
    window.location.href = redirect_url
  } else {
    toast?.showErrorToasted(getOverseasTxtByLang(language, "failedCheckout"))
  }
}

const openSelectGift = (index: number, isNotGift: boolean = false): void => {
  selectGiftIndex.value = index
  isNotWrappingSelectGift.value = isNotGift
  visibleSelectGift.value = true
}

watch(
  () => store.cartList.length,
  (value: number) => {
    if (value === 0 && store.eGift.is_select) store.resetEGift()
  },
)

watch(
  () => route.fullPath,
  () => {
    closeCart()
  },
)

onMounted(() => {
  isLoaded.value = true
})
</script>
<style scoped>
.side-cart {
  z-index: 120;
  position: relative;
}
</style>
