<template>
  <div v-if="cartList" class="order-cart-list">
    <div v-for="(item, _index) in cartList" :key="_index" class="_item flex">
      <div class="_product flex">
        <div class="_left">
          <AtomLink :aria-label="item.product.name" class="_image-box">
            <AtomImage
              class="_img"
              :src="item.sku.main_image"
              :size="0.7"
              :sp-size="0.6"
              :alt="`${item.product.name} - ${item.sku.name}${item.sku.size ? `(${item.sku.size})` : null}の画像`"
            />
          </AtomLink>
        </div>

        <div class="_right">
          <div class="_description-box">
            <div class="_name-sku">
              <AtomLink class="_name _side-cart-link second-dotted-line">
                <p>{{ item.product.name }}</p>
              </AtomLink>
              <div class="_sku">
                <p class="_material">{{ item.product.material }}</p>
                <p>
                  <span v-if="item.sku.size" class="_size"
                    >{{ getOverseasTxtByLang(language, "size") }}: {{ item.sku.size }}</span
                  >
                  <span v-if="item.sku.color" class="_color"
                    >/ {{ getOverseasTxtByLang(language, "color") }}: {{ item.sku.color }}</span
                  >
                </p>
              </div>
            </div>
            <!-- <div v-if="isSubscription">
              <OrderCartRecurringSelect :product-and-sku="_cartItem" />
            </div> -->
            <div class="_price-and-quantity flex">
              <div class="_price">
                <p v-if="item.discounted_price === item.price">
                  ¥{{ priceWithComma(item.reference_price) }} ({{ getOverseasRange(language, item.reference_price) }})
                </p>
                <div v-else>
                  <p class="_discount_announce">{{ isSubscription ? "定期購入割引" : "クーポン適用中" }}</p>
                  <p class="_strike">¥{{ priceWithComma(item.reference_price) }}</p>
                  <p class="_discounted">
                    ¥{{ priceWithComma(item.reference_discounted_price) }}
                    <span>({{ getOverseasTxtByLang(language, "taxInclude") }})</span>
                  </p>
                </div>
              </div>
              <div v-if="!item.gifts?.length && !item.embroideries?.length" class="_quantity-box">
                <div class="_quantity-adjust flex flex-y-center">
                  <div v-show="!isConfirm" class="_reduce">
                    <button class="_button" @click="reduceCartItem(item.sku.document_id, item.quantity)"></button>
                  </div>
                  <div class="_quantity">
                    <p>{{ (isConfirm ? "数量: " : "") + item.quantity }}</p>
                  </div>
                  <div v-show="!isConfirm" class="_increase">
                    <button
                      class="_button"
                      :disabled="isExchangeCoupon"
                      @click="
                        addCartItem(
                          item.product.document_id,
                          item.sku.document_id,
                          item.sku.site_id,
                          item.quantity,
                          item.sku.is_reservation,
                          item.sku.net_stock_quantity,
                          item.sku.net_stock_quantity_limit,
                          item.product.order_quantity_limit,
                          item.product.order_quantity_limit_type,
                        )
                      "
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CartGift, CartEmbroidery } from "@tential/ec-gql-schema/models/order"
import { SkuWithGift, SkuWithEmbroidery, ProductAndSku } from "@tential/ec-gql-schema/types/cart"
import { getOverseasRange, getOverseasTxtByLang } from "~/utils/functions/overseas"
import { useConfirmDialog } from "~/composables/useConfirmDialog"
import { useCart } from "~/composables/useCart"
import { priceWithComma } from "~/utils/functions/price"
import { useToast } from "~/state"

const props = defineProps({
  cartList: {
    type: Array as () => ProductAndSku[],
    default: () => [],
  },
  totalQuantity: {
    type: Number,
    default: 0,
  },
  isSubscription: {
    type: Boolean,
    default: false,
  },
  isConfirm: {
    type: Boolean,
    default: false,
  },
  margin: {
    type: Number,
    required: false,
    default: null,
  },
  isExchangeCoupon: {
    type: Boolean,
    default: false,
  },
})

const { proceed } = useConfirmDialog()
const toast = useToast()
const cart = useCart()
const gtag = useGtag()
const config = useRuntimeConfig()
const language = navigator.language
// const isSubscription = props.isSubscription

/** ギフト商品＆ラッピング済み商品を除いた商品の数量 */
const notGiftSkuQuantity = computed(() => {
  return cart.cartLine.value.reduce((total, cartItem) => {
    if (!cartItem.product.is_gift) return total + cartItem.count
    return total
  }, 0)
})

const reduceCartItem = async (
  _skuId: string,
  _skuAmt: number,
  gift?: SkuWithGift,
  embroidery?: SkuWithEmbroidery,
): Promise<void> => {
  if (_skuAmt === 1) {
    if (!(await proceed("Are you sure you want to delete?"))) return
  }

  await cart.removeCartItemOld(_skuId, gift, embroidery)

  // カートに購入点数制限がある商品があったら同時に削除
  if (cart.cartLine.value.length > 0) {
    for (const item of cart.cartLine.value) {
      if (item.product.order_quantity_limit && item.product.order_quantity_limit > 0) {
        if (item.product.order_quantity_limit_type === "order") {
          // 数量が購入点数制限以上の場合
          if (item.quantity > item.product.order_quantity_limit) {
            toast?.showErrorToasted(`${item.product.name}は、購入点数上限を超えたためカートから削除されました`)
            await cart.removeCartItem({ skuId: item.sku.document_id })
          }
        } else if (item.product.order_quantity_limit_type === "not_gift_sku") {
          // 数量がカート個数*購入点数制限以上の場合
          if (item.quantity > item.product.order_quantity_limit * notGiftSkuQuantity.value) {
            toast?.showErrorToasted(`${item.product.name}は、購入点数上限を超えたためカートから削除されました`)
            await cart.removeCartItem({ skuId: item.sku.document_id })
          }
        }
      }
    }
  }

  // if (props.isSubscription) {
  //   store.dispatch("reduceSubscriptionCartList", _skuId)
  // } else if (gift) {
  //   store.dispatch("reduceCartList", { _skuId, gift })
  // } else {
  //   store.dispatch("reduceCartList", { _skuId })
  // }
}

// カートから追加
const addCartItem = async (
  _productId: string,
  _skuId: string,
  _site_id?: string,
  _quantity?: number,
  _is_reservation?: boolean,
  _net_stock_quantity?: number,
  _net_stock_quantity_limit?: number,
  _order_quantity_limit?: number,
  _order_quantity_limit_type?: string,
  gift?: SkuWithGift,
  embroidery?: SkuWithGift["embroidery"],
) => {
  if (props.isExchangeCoupon === true) return
  if (props.isSubscription) {
    // store.dispatch("addSubscriptionCartList", {
    //   productId: _productId,
    //   skuId: _skuId,
    //   siteId: _site_id,
    //   subscriptionItemId: _subscriptionItemId,
    //   isReservation: _is_reservation,
    // })
  } else {
    // 購入点数制限がある商品の点数チェック
    if (_quantity && _order_quantity_limit && _order_quantity_limit > 0 && _order_quantity_limit_type) {
      if (_order_quantity_limit_type === "order") {
        // 数量が購入点数制限以上の場合はエラー
        if (_quantity >= _order_quantity_limit) {
          toast?.showErrorToasted(`この商品は、1回のご注文につき、${_order_quantity_limit}個まで購入できます`)
          return
        }
      } else if (_order_quantity_limit_type === "not_gift_sku") {
        // 数量がカート個数*購入点数制限以上の場合はエラー
        if (_quantity >= _order_quantity_limit * notGiftSkuQuantity.value) {
          toast?.showErrorToasted(
            `この商品は、商品1点（ラッピング袋・カードを除く）につき、${_order_quantity_limit}個まで購入できます`,
          )
          return
        }
      }
    }

    if (_is_reservation && _quantity && Number(_net_stock_quantity) - _quantity < Number(_net_stock_quantity_limit)) {
      const availableQuantity =
        (_net_stock_quantity ?? 0) - (_net_stock_quantity_limit ?? 0) + 1 < 0
          ? 0
          : (_net_stock_quantity ?? 0) - (_net_stock_quantity_limit ?? 0) + 1
      toast?.showErrorToasted(`予約購入できる個数は${availableQuantity}個までです。`)
      return
    }

    await cart.addCartItem({
      product_id: _productId,
      sku_id: _skuId,
      site_id: _site_id,
      is_reservation: _is_reservation,
      net_stock_quantity: _net_stock_quantity,
      net_stock_quantity_limit: _net_stock_quantity_limit,
      gift: gift ? formatGift(gift) : undefined,
      embroidery: embroidery ? formatEmbroidery(embroidery) : undefined,
    })
  }
  gaPush(_skuId)
}

const gaPush = (_skuId: string) => {
  if (config.public.APP_ENV === "production") {
    gtag("event", "add_to_cart", {
      currency: "JPY",
      items: [
        {
          item_id: _skuId,
        },
      ],
    })
  }
}

const formatGift = (gift: SkuWithGift): CartGift => {
  return {
    sku_id: gift.sku_id,
    message: gift.message,
    product_name: gift.product_name,
    sku_name: gift.sku_name,
    sku_code: gift.sku_code,
    size: gift.size,
    price: gift.price,
    img: gift.img,
    message_img: gift.message_img,
    message_price: gift.message_price,
    message_sku_code: gift.message_sku_code,
    message_sku_id: gift.message_sku_id,
    card: gift.card,
    card_img: gift.card_img,
    card_price: gift.card_price,
    card_sku_code: gift.card_sku_code,
    card_sku_id: gift.card_sku_id,
    product_id: gift.product_id,
  }
}

const formatEmbroidery = (embroidery: SkuWithGift["embroidery"]): CartEmbroidery => {
  const formatEmbroidery = {
    text: embroidery.text,
    position: embroidery.position,
    color: embroidery.color,
    font: embroidery.font,
    price: embroidery.price,
  }
  return formatEmbroidery
}
</script>
